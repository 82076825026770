import React, { ReactElement } from "react";

export default function EligibleInjuryIcon(): ReactElement {
    return (
        <svg
            width="130"
            height="127"
            viewBox="0 0 130 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="61" cy="62" r="61" fill="#6A4A9E" />
            <circle cx="68" cy="65" r="60.5" stroke="#C6E7FA" />
            <g clipPath="url(#clip0_1_359)">
                <path
                    d="M61 45.8851C64.7927 45.8851 67.878 42.7703 67.878 38.9426C67.878 35.1148 64.7927 32 61 32C57.2073 32 54.1219 35.1158 54.1219 38.9426C54.1219 42.7693 57.2073 45.8851 61 45.8851ZM61 33.4771C62.0709 33.4771 63.1178 33.7977 64.0082 34.3982C64.8986 34.9988 65.5926 35.8524 66.0025 36.851C66.4123 37.8497 66.5195 38.9486 66.3106 40.0088C66.1017 41.069 65.586 42.0429 64.8287 42.8072C64.0715 43.5716 63.1067 44.0921 62.0563 44.303C61.006 44.5139 59.9173 44.4056 58.9279 43.992C57.9385 43.5783 57.0929 42.8778 56.4979 41.979C55.9029 41.0802 55.5854 40.0235 55.5854 38.9426C55.5869 37.4935 56.1579 36.1043 57.173 35.0797C58.1881 34.055 59.5644 33.4787 61 33.4771V33.4771Z"
                    fill="white"
                />
                <path
                    d="M46.8425 45.6247C50.443 45.6247 53.3723 42.6679 53.3723 39.0337C53.3723 35.3994 50.4454 32.4431 46.8425 32.4431C43.2396 32.4431 40.3132 35.3974 40.3132 39.0337C40.3132 42.6699 43.244 45.6247 46.8425 45.6247ZM46.8425 33.9203C47.8446 33.9202 48.8242 34.22 49.6574 34.7819C50.4906 35.3438 51.1401 36.1425 51.5236 37.0769C51.9071 38.0114 52.0075 39.0397 51.812 40.0317C51.6165 41.0237 51.134 41.935 50.4254 42.6502C49.7169 43.3654 48.8141 43.8525 47.8313 44.0498C46.8484 44.2471 45.8297 44.1458 44.904 43.7586C43.9782 43.3715 43.1869 42.716 42.6303 41.875C42.0736 41.0339 41.7766 40.0451 41.7766 39.0337C41.7786 37.6781 42.3129 36.3786 43.2625 35.4201C44.2122 34.4616 45.4995 33.9222 46.8425 33.9203V33.9203Z"
                    fill="white"
                />
                <path
                    d="M51.1678 87.0363C50.7857 87.3618 50.3019 87.5404 49.802 87.5405H46.9405C46.9082 87.5356 46.8756 87.533 46.8429 87.5326H43.8937C43.3324 87.532 42.7943 87.3066 42.3974 86.906C42.0005 86.5054 41.7772 85.9622 41.7766 85.3957V70.4948C41.7766 70.2989 41.6995 70.111 41.5623 69.9725C41.4251 69.834 41.2389 69.7562 41.0449 69.7562C40.3605 69.7552 39.7045 69.4803 39.2206 68.9919C38.7368 68.5034 38.4644 67.8413 38.4634 67.1505V53.5151C38.4646 52.403 38.9027 51.3369 39.6817 50.5505C40.4606 49.7641 41.5168 49.3218 42.6185 49.3205H49.8566C50.0506 49.3205 50.2368 49.2427 50.374 49.1041C50.5112 48.9656 50.5883 48.7778 50.5883 48.5819C50.5883 48.386 50.5112 48.1982 50.374 48.0596C50.2368 47.9211 50.0506 47.8433 49.8566 47.8433H42.6185C41.1289 47.8451 39.7008 48.4433 38.6475 49.5066C37.5942 50.5699 37.0017 52.0114 37 53.5151V67.1505C37.0012 68.105 37.3331 69.0289 37.9382 69.7623C38.5433 70.4957 39.3835 70.9924 40.3132 71.1664V85.3962C40.3143 86.3543 40.6919 87.2729 41.3632 87.9504C42.0344 88.6279 42.9444 89.0091 43.8937 89.0102H46.7561C46.7884 89.0151 46.821 89.0177 46.8537 89.0181H49.8024C50.648 89.018 51.4661 88.7159 52.1122 88.1653C52.1856 88.1027 52.2461 88.0262 52.2902 87.94C52.3343 87.8538 52.3611 87.7597 52.3692 87.663C52.3772 87.5664 52.3664 87.4691 52.3372 87.3767C52.308 87.2842 52.261 87.1985 52.199 87.1244C52.137 87.0503 52.0612 86.9893 51.9758 86.9448C51.8904 86.9003 51.7972 86.8732 51.7014 86.865C51.6056 86.8569 51.5092 86.8679 51.4177 86.8973C51.3261 86.9268 51.2412 86.9742 51.1678 87.0368V87.0363Z"
                    fill="white"
                />
                <path
                    d="M60.9244 57.3556C57.8219 57.3557 54.8154 58.4416 52.4171 60.4284C50.0189 62.4151 48.3774 65.1797 47.7722 68.2512C47.167 71.3227 47.6356 74.5109 49.0981 77.2727C50.5607 80.0345 52.9267 82.199 55.7931 83.3974C58.6594 84.5958 61.8488 84.7539 64.8177 83.8448C67.7866 82.9357 70.3514 81.0157 72.075 78.4118C73.7987 75.808 74.5746 72.6814 74.2705 69.5649C73.9664 66.4484 72.6011 63.5347 70.4073 61.3203C69.1649 60.0593 67.687 59.0597 66.0593 58.3792C64.4315 57.6986 62.6862 57.3507 60.9244 57.3556V57.3556ZM69.3727 79.4192C67.4182 81.3922 64.8464 82.6202 62.0955 82.8938C59.3446 83.1674 56.5848 82.4697 54.2864 80.9196C51.988 79.3696 50.2931 77.0631 49.4906 74.3931C48.6882 71.7231 48.8277 68.8548 49.8855 66.2771C50.9433 63.6993 52.8538 61.5715 55.2916 60.2562C57.7294 58.9409 60.5437 58.5195 63.2548 59.0638C65.9659 59.6081 68.4061 61.0845 70.1597 63.2413C71.9133 65.3982 72.8717 68.102 72.8717 70.8922C72.8759 72.4764 72.5689 74.0458 71.9683 75.5094C71.3677 76.9731 70.4855 78.302 69.3727 79.4192V79.4192Z"
                    fill="white"
                />
                <path
                    d="M67.4546 65.7591L58.3546 74.1458L54.3273 70.1147C54.1888 69.9806 54.0036 69.9069 53.8117 69.9093C53.6198 69.9118 53.4365 69.9902 53.3014 70.1278C53.1662 70.2654 53.09 70.451 53.0892 70.6447C53.0884 70.8385 53.1631 71.0247 53.2971 71.1634L57.819 75.6894C57.9523 75.8228 58.1313 75.8994 58.319 75.9033C58.5066 75.9073 58.6886 75.8382 58.8273 75.7106L68.441 66.8477C68.513 66.7827 68.5716 66.7038 68.6133 66.6158C68.655 66.5277 68.679 66.4322 68.6838 66.3347C68.6887 66.2372 68.6744 66.1397 68.6418 66.0479C68.6091 65.956 68.5588 65.8716 68.4936 65.7995C68.4284 65.7274 68.3497 65.6691 68.2621 65.6278C68.1744 65.5866 68.0796 65.5633 67.983 65.5594C67.8864 65.5554 67.7899 65.5708 67.6992 65.6046C67.6085 65.6385 67.5254 65.6901 67.4546 65.7566V65.7591Z"
                    fill="white"
                />
                <path
                    d="M75.1576 45.6247C78.7581 45.6247 81.6868 42.6679 81.6868 39.0337C81.6868 35.3994 78.76 32.4431 75.1576 32.4431C71.5551 32.4431 68.6278 35.3974 68.6278 39.0337C68.6278 42.6699 71.5571 45.6247 75.1576 45.6247ZM75.1576 33.9203C76.1596 33.9203 77.1392 34.2202 77.9723 34.7822C78.8055 35.3441 79.4549 36.1428 79.8383 37.0773C80.2217 38.0117 80.322 39.04 80.1265 40.032C79.931 41.024 79.4484 41.9352 78.7398 42.6504C78.0313 43.3655 77.1285 43.8525 76.1457 44.0498C75.1629 44.2471 74.1442 44.1457 73.2184 43.7586C72.2927 43.3715 71.5015 42.7159 70.9448 41.8749C70.3882 41.0339 70.0911 40.0451 70.0912 39.0337C70.0932 37.678 70.6276 36.3785 71.5773 35.4199C72.527 34.4614 73.8145 33.9221 75.1576 33.9203Z"
                    fill="white"
                />
                <path
                    d="M79.3814 47.8433H72.1434C71.9493 47.8433 71.7632 47.9211 71.626 48.0596C71.4887 48.1982 71.4116 48.386 71.4116 48.5819C71.4116 48.7778 71.4887 48.9656 71.626 49.1041C71.7632 49.2427 71.9493 49.3205 72.1434 49.3205H79.3814C80.4831 49.3218 81.5393 49.7641 82.3183 50.5505C83.0973 51.3369 83.5354 52.403 83.5365 53.5151V67.1505C83.5355 67.8413 83.2632 68.5034 82.7793 68.9919C82.2954 69.4803 81.6394 69.7552 80.9551 69.7562C80.761 69.7562 80.5749 69.834 80.4377 69.9725C80.3005 70.111 80.2234 70.2989 80.2234 70.4948V85.3957C80.2227 85.9622 79.9995 86.5054 79.6026 86.906C79.2057 87.3066 78.6676 87.532 78.1063 87.5326H75.1575C75.1248 87.533 75.0923 87.5356 75.0599 87.5405H72.198C71.698 87.5404 71.2142 87.3618 70.8321 87.0363C70.6839 86.9099 70.4919 86.8481 70.2985 86.8645C70.1051 86.881 69.9261 86.9743 69.8009 87.1239C69.6757 87.2736 69.6145 87.4673 69.6308 87.6625C69.647 87.8577 69.7395 88.0384 69.8877 88.1648C70.5338 88.7154 71.352 89.0175 72.1975 89.0176H75.1463C75.1789 89.0172 75.2115 89.0146 75.2438 89.0098H78.1058C79.0551 89.0086 79.9651 88.6274 80.6363 87.9499C81.3075 87.2724 81.6851 86.3538 81.6863 85.3957V71.1659C82.616 70.992 83.4562 70.4955 84.0614 69.7621C84.6666 69.0288 84.9986 68.1049 85 67.1505V53.5151C84.9983 52.0114 84.4058 50.5699 83.3525 49.5066C82.2992 48.4433 80.8711 47.8451 79.3814 47.8433Z"
                    fill="white"
                />
                <path
                    d="M67.1463 84.4887C66.9523 84.4887 66.7662 84.5666 66.6289 84.7051C66.4917 84.8436 66.4146 85.0314 66.4146 85.2273V88.2126C66.4135 88.8249 66.1719 89.4118 65.743 89.8447C65.314 90.2777 64.7325 90.5213 64.1259 90.5224H57.8741C57.2675 90.5213 56.686 90.2777 56.257 89.8447C55.828 89.4118 55.5865 88.8249 55.5854 88.2126V85.2273C55.5854 85.0314 55.5083 84.8436 55.3711 84.7051C55.2338 84.5666 55.0477 84.4887 54.8537 84.4887C54.6596 84.4887 54.4735 84.5666 54.3363 84.7051C54.199 84.8436 54.1219 85.0314 54.1219 85.2273V88.2126C54.1231 89.2167 54.5188 90.1793 55.2223 90.8892C55.9257 91.5992 56.8794 91.9985 57.8741 91.9995H64.1259C65.1206 91.9985 66.0743 91.5992 66.7777 90.8892C67.4812 90.1793 67.8769 89.2167 67.878 88.2126V85.2273C67.878 85.0314 67.801 84.8436 67.6637 84.7051C67.5265 84.5666 67.3404 84.4887 67.1463 84.4887V84.4887Z"
                    fill="white"
                />
                <path
                    d="M65.4775 48.4474H56.5224C54.9545 48.4491 53.4514 49.0787 52.3429 50.1979C51.2343 51.3171 50.611 52.8345 50.6097 54.4171V58.6712C50.6097 58.8671 50.6868 59.055 50.8241 59.1935C50.9613 59.332 51.1474 59.4098 51.3414 59.4098C51.5355 59.4098 51.7216 59.332 51.8588 59.1935C51.9961 59.055 52.0732 58.8671 52.0732 58.6712V54.4171C52.0744 53.2264 52.5436 52.0848 53.3777 51.2429C54.2119 50.4009 55.3428 49.9274 56.5224 49.9261H65.4775C66.6571 49.9275 67.788 50.4011 68.6221 51.243C69.4562 52.0849 69.9254 53.2264 69.9268 54.4171V58.6712C69.9268 58.8671 70.0039 59.055 70.1411 59.1935C70.2783 59.332 70.4645 59.4098 70.6585 59.4098C70.8526 59.4098 71.0387 59.332 71.1759 59.1935C71.3131 59.055 71.3902 58.8671 71.3902 58.6712V54.4171C71.3889 52.8345 70.7656 51.3171 69.6571 50.1979C68.5486 49.0787 67.0454 48.4491 65.4775 48.4474V48.4474Z"
                    fill="white"
                />
            </g>
            <path
                d="M24.3205 56.5688C23.8054 56.5688 23.3113 56.3642 22.9471 56.0002C22.5828 55.6362 22.3781 55.1425 22.3781 54.6277V52.2264H19.9424C19.4272 52.2264 18.9332 52.0219 18.5689 51.6579C18.2046 51.2939 18 50.8001 18 50.2853C18 49.7706 18.2046 49.2768 18.5689 48.9128C18.9332 48.5488 19.4272 48.3443 19.9424 48.3443H22.3781V45.941C22.3781 45.4262 22.5828 44.9325 22.9471 44.5685C23.3113 44.2045 23.8054 44 24.3205 44C24.8357 44 25.3298 44.2045 25.6941 44.5685C26.0583 44.9325 26.263 45.4262 26.263 45.941V48.3417H28.7013C29.2165 48.3417 29.7105 48.5462 30.0748 48.9102C30.4391 49.2743 30.6437 49.768 30.6437 50.2828C30.6437 50.7975 30.4391 51.2913 30.0748 51.6553C29.7105 52.0193 29.2165 52.2238 28.7013 52.2238H26.2656V54.6251C26.2659 54.8805 26.2159 55.1334 26.1182 55.3693C26.0206 55.6053 25.8774 55.8197 25.6967 56.0002C25.516 56.1808 25.3014 56.3239 25.0653 56.4215C24.8292 56.519 24.5761 56.5691 24.3205 56.5688V56.5688Z"
                fill="#C6E7FA"
            />
            <path
                d="M96.5375 86.8108C96.444 86.1681 96.1617 85.5675 95.7265 85.0851C95.2913 84.6026 94.7227 84.26 94.0926 84.1005C93.4625 83.9411 92.7993 83.9719 92.1867 84.1892C91.5742 84.4065 91.0399 84.8003 90.6514 85.3211C90.2629 85.8418 90.0378 86.466 90.0043 87.1147C89.9709 87.7633 90.1308 88.4073 90.4637 88.9652C90.7966 89.5231 91.2875 89.9697 91.8745 90.2487C92.4615 90.5277 93.1181 90.6265 93.7612 90.5325C94.1881 90.4703 94.5985 90.3247 94.969 90.104C95.3395 89.8833 95.6629 89.5919 95.9207 89.2463C96.1786 88.9007 96.3657 88.5077 96.4716 88.0899C96.5774 87.672 96.5998 87.2374 96.5375 86.8108V86.8108Z"
                fill="#C6E7FA"
            />
            <path
                d="M98.6734 95.6375C98.63 95.3432 98.5003 95.0683 98.3007 94.8476C98.1011 94.6269 97.8405 94.4703 97.5518 94.3975C97.2632 94.3247 96.9594 94.3391 96.6789 94.4388C96.3984 94.5385 96.1537 94.719 95.9758 94.9575C95.7979 95.1961 95.6948 95.482 95.6795 95.7791C95.6642 96.0762 95.7373 96.3712 95.8897 96.6267C96.0421 96.8823 96.2668 97.087 96.5356 97.215C96.8044 97.343 97.1051 97.3885 97.3997 97.3458C97.7947 97.288 98.1506 97.076 98.3892 96.7561C98.6278 96.4363 98.7295 96.0349 98.6721 95.6402L98.6734 95.6375Z"
                fill="#C6E7FA"
            />
            <path
                d="M32.8348 41.5925C32.8346 41.1498 32.7031 40.7171 32.4569 40.349C32.2106 39.981 31.8607 39.6942 31.4513 39.5249C31.0419 39.3557 30.5915 39.3115 30.157 39.398C29.7225 39.4846 29.3234 39.6979 29.0103 40.0111C28.6971 40.3243 28.4839 40.7233 28.3977 41.1576C28.3115 41.5918 28.356 42.0419 28.5258 42.4508C28.6956 42.8597 28.9829 43.2092 29.3514 43.4549C29.7199 43.7007 30.1531 43.8318 30.5961 43.8315C31.1901 43.8311 31.7596 43.5951 32.1794 43.1752C32.5992 42.7553 32.8349 42.186 32.8348 41.5925V41.5925Z"
                fill="#C6E7FA"
            />
            <defs>
                <clipPath id="clip0_1_359">
                    <rect
                        width="48"
                        height="60"
                        fill="white"
                        transform="translate(37 32)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
