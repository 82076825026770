import React, { ReactElement } from "react";
import colors from "@styles/variables/workersCompensationRatesVariables.module.scss";
import styles from "./styles.module.scss";

import EligibleInjuryIcon from "../icons/eligibleInjuryIcon";
import EmployeeSeeksMedicalCareIcon from "../icons/employeeSeeksMedicalCareIcon";
import EmployeeReportsIcon from "../icons/employeeReportsIcon";
import InsurerInvestigatesIcon from "../icons/insurerInvestigatesIcon";
import BenefitsIcon from "../icons/benefitsIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "An eligible injury at work happens",
        description:
            "In general, workers' comp benefits apply to employees who are injured while performing their jobs. Injuries that happen at the workplace but not on the clock — for instance, if a lifeguard decides to go swimming after a shift — aren't covered.",
        icon: <EligibleInjuryIcon />,
    },
    {
        title: "The employee seeks medical care",
        description:
            "The employee should seek medical care when needed. Most health care providers will ask a patient if an injury was work-related, because they'll need to send the bill either to the employer or the workers' comp insurance provider if it was. The patient may need to pay a bill out of pocket, then be reimbursed later.",
        icon: <EmployeeSeeksMedicalCareIcon />,
    },
    {
        title: "The employee reports the injury and the employer files a claim",
        description:
            "The workers' comp process starts with employees reporting on-the-job injuries promptly. Delays, especially beyond 30 days in California, may lead to loss of benefits. After reporting, the company provides a claims form, collecting details about the injury. Employers must then file a claim with their insurance within a specified timeframe and may also need to inform the state workers' compensation department.",
        icon: <EmployeeReportsIcon />,
    },
    {
        title: "The insurer investigates the claim",
        description:
            "Once a claim has been filed, the insurance company will investigate it, then decide whether to approve or deny it. This process may include interviews with the employer and employee and reviews of any relevant documents.",
        icon: <InsurerInvestigatesIcon />,
    },
    {
        title: "Workers' comp benefits are paid",
        description:
            "If the claim is approved, the injured employee can begin receiving payment to cover medical bills and lost wages. In most states, workers' comp will pay around two-thirds of an injured worker's weekly wages.",
        icon: <BenefitsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="How Does Workers Compensation Work?"
            mainDescription="Here's the process employers can typically expect when using
                   their workers' compensation insurance."
            classes={{
                mainSectionClasses:
                    "max-w-7xl mx-auto mt-10 lg:mt-16 block px-4vw lg:px-4",
                mainTitleClasses:
                    "text-center font-bold text-xl capitalize lg:text-4xl mb-4",
                mainDescriptionClasses: `w-full text-center bold text-base md:text-xl mx-auto mb-12 ${styles["brief"]}`,
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl bold mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainTitleColor: colors.whyUsTitle,
                titleColor: colors.whyUsItemTitle,
                descriptionColor: colors.whyUsItemBrief,
            }}
        />
    );
}
