import React, { ReactElement } from "react";

export default function InsurerInvestigatesIcon(): ReactElement {
    return (
        <svg
            width="130"
            height="127"
            viewBox="0 0 130 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="62" cy="61" r="61" fill="#6A4A9E" />
            <circle cx="67" cy="66" r="60.5" stroke="#C6E7FA" />
            <g clipPath="url(#clip0_1_359_cc)">
                <path
                    d="M65.7881 64.2871C58.6121 70.6342 47.6491 69.9634 41.302 62.7917C34.9549 55.62 35.6272 44.6548 42.7974 38.3063C49.9677 31.9578 60.9364 32.6307 67.2842 39.8017C73.632 46.9728 72.9598 57.9393 65.7888 64.2864L65.7881 64.2871ZM69.4989 37.8491C62.0723 29.4533 49.2464 28.6671 40.8498 36.0952C32.4532 43.5233 31.6714 56.3471 39.0987 64.7422C46.5261 73.1373 59.3527 73.9228 67.7464 66.4961C76.1401 59.0695 76.9277 46.2443 69.4989 37.8491V37.8491Z"
                    fill="white"
                />
                <path
                    d="M71.208 70.5595C71.6078 70.2024 72.0815 69.9378 72.5952 69.7847L73.293 69.5789L69.9304 65.6487L69.5836 66.029C69.2016 66.4483 68.7975 66.8469 68.373 67.2232C67.9483 67.5982 67.504 67.9505 67.0421 68.2785L66.6212 68.5769L69.9845 72.5071L70.2737 71.8413C70.4876 71.3506 70.8068 70.913 71.2087 70.5595H71.208Z"
                    fill="white"
                />
                <path
                    d="M89.0515 84.6123L77.1798 71.2837C76.4264 70.4334 75.3084 70.0232 74.1135 70.1571C73.261 70.2565 72.4602 70.6174 71.8212 71.1904C70.1897 72.6346 69.939 75.0251 71.2622 76.5199L83.1345 89.8485C83.8865 90.6988 85.0031 91.109 86.1966 90.9751C87.0497 90.8755 87.8511 90.5146 88.491 89.9418C90.1218 88.4976 90.3717 86.1071 89.0486 84.6123H89.0515Z"
                    fill="white"
                />
                <path
                    d="M54.3352 44.7403C53.453 44.7403 52.5794 44.914 51.7644 45.2517C50.9493 45.5893 50.2088 46.0841 49.585 46.708C48.9612 47.3318 48.4665 48.0724 48.1289 48.8875C47.7914 49.7026 47.6177 50.5762 47.6178 51.4584C47.6178 52.3405 47.7916 53.2139 48.1291 54.0289C48.4667 54.8438 48.9616 55.5843 49.5853 56.208C50.2091 56.8317 50.9496 57.3265 51.7646 57.664C52.5796 58.0015 53.4531 58.1751 54.3352 58.175C55.2174 58.1753 56.0911 58.0018 56.9062 57.6644C57.7214 57.327 58.4621 56.8323 59.086 56.2085C59.7099 55.5848 60.2048 54.8443 60.5425 54.0292C60.8802 53.2142 61.054 52.3406 61.054 51.4584C61.054 50.5761 60.8802 49.7024 60.5426 48.8873C60.2049 48.0722 59.71 47.3315 59.0861 46.7077C58.4621 46.0839 57.7215 45.589 56.9063 45.2514C56.0912 44.9139 55.2175 44.7402 54.3352 44.7403ZM54.3352 56.5058C52.9967 56.5053 51.7132 55.9733 50.7667 55.0268C49.8203 54.0804 49.2883 52.7969 49.2877 51.4584C49.2881 50.1197 49.8199 48.8359 50.7664 47.8892C51.7129 46.9424 52.9965 46.4102 54.3352 46.4095C55.6739 46.4098 56.9577 46.942 57.9041 47.8888C58.8505 48.8356 59.3821 50.1196 59.382 51.4584C59.3818 52.7969 58.85 54.0805 57.9036 55.027C56.9572 55.9735 55.6737 56.5055 54.3352 56.5058V56.5058Z"
                    fill="white"
                />
                <path
                    d="M54.3352 47.9768C54.1763 47.9772 54.0177 47.9882 53.8602 48.0096C54.1082 48.3333 54.2424 48.7298 54.2419 49.1376C54.2419 49.3813 54.1939 49.6226 54.1007 49.8477C54.0074 50.0729 53.8707 50.2775 53.6984 50.4498C53.5261 50.6221 53.3215 50.7588 53.0963 50.8521C52.8712 50.9453 52.6299 50.9933 52.3862 50.9933C52.1295 50.9936 51.8757 50.9404 51.6407 50.8373C51.4057 50.7343 51.1946 50.5834 51.021 50.3944C50.9109 50.7383 50.8549 51.0973 50.8551 51.4583C50.8557 52.3812 51.2225 53.266 51.875 53.9185C52.5276 54.5711 53.4124 54.9379 54.3352 54.9385C55.2581 54.9383 56.1431 54.5715 56.7956 53.9189C57.4481 53.2663 57.8146 52.3812 57.8146 51.4583C57.8148 50.5353 57.4483 49.65 56.7958 48.9971C56.1434 48.3442 55.2583 47.9772 54.3352 47.9768V47.9768Z"
                    fill="white"
                />
                <path
                    d="M68.6693 51.4576C66.2446 53.9572 60.2998 58.4827 54.3351 58.4827C48.3833 58.4827 42.4328 53.9565 40.0031 51.4576C42.43 48.9581 48.3769 44.4326 54.3351 44.4326C60.2934 44.4326 66.2389 48.9581 68.6693 51.4576ZM70.634 50.8566C70.5628 50.774 63.1682 42.5804 54.3351 42.5804C45.5021 42.5804 38.1082 50.774 38.0369 50.8566C37.8947 51.024 37.8165 51.2365 37.8165 51.4562C37.8165 51.6759 37.8947 51.8884 38.0369 52.0558C38.054 52.0758 39.9326 54.1879 43.0403 56.32C45.7086 58.1508 49.8331 60.332 54.3351 60.332C58.8371 60.332 62.9624 58.1508 65.6307 56.32C68.7391 54.1836 70.6155 52.0758 70.6348 52.0558C70.7771 51.8884 70.8552 51.6759 70.8552 51.4562C70.8552 51.2365 70.7771 51.024 70.6348 50.8566H70.634Z"
                    fill="white"
                />
                <path
                    d="M80.838 48.076C80.6724 48.4866 80.6322 48.9371 80.7227 49.3705C80.8133 49.804 81.0303 50.201 81.3465 50.5113C81.6627 50.8215 82.0639 51.0312 82.4991 51.1136C82.9344 51.1961 83.3844 51.1477 83.792 50.9746C84.1997 50.8015 84.5467 50.5114 84.7893 50.141C85.0319 49.7706 85.1591 49.3367 85.1548 48.8939C85.1506 48.4511 85.015 48.0195 84.7654 47.6536C84.5157 47.2878 84.1631 47.004 83.7522 46.8383C83.2014 46.6162 82.5849 46.6218 82.0384 46.8539C81.4919 47.086 81.0601 47.5256 80.838 48.076V48.076Z"
                    fill="#C6E7FA"
                />
            </g>
            <path
                d="M90.3233 32C90.8384 32 91.3325 32.2045 91.6967 32.5685C92.061 32.9325 92.2657 33.4262 92.2657 33.941L92.2657 36.3424L94.7014 36.3423C95.2166 36.3423 95.7106 36.5468 96.0749 36.9109C96.4392 37.2749 96.6438 37.7686 96.6438 38.2834C96.6438 38.7982 96.4392 39.2919 96.0749 39.6559C95.7106 40.0199 95.2166 40.2244 94.7014 40.2244L92.2657 40.2244L92.2657 42.6277C92.2657 43.1425 92.061 43.6362 91.6967 44.0002C91.3325 44.3642 90.8384 44.5687 90.3233 44.5687C89.8081 44.5687 89.314 44.3642 88.9497 44.0002C88.5855 43.6362 88.3808 43.1425 88.3808 42.6277L88.3808 40.227L85.9425 40.227C85.4273 40.227 84.9333 40.0225 84.569 39.6585C84.2047 39.2945 84.0001 38.8008 84.0001 38.286C84.0001 37.7712 84.2047 37.2775 84.569 36.9135C84.9333 36.5494 85.4273 36.3449 85.9425 36.3449L88.3782 36.3449L88.3782 33.9436C88.3779 33.6883 88.4279 33.4354 88.5256 33.1994C88.6232 32.9634 88.7664 32.749 88.9471 32.5685C89.1278 32.3879 89.3424 32.2448 89.5785 32.1472C89.8146 32.0497 90.0677 31.9996 90.3233 32V32Z"
                fill="#C6E7FA"
            />
            <path
                d="M28.0601 78.0034C27.8442 78.2081 27.694 78.4723 27.6282 78.7626C27.5625 79.0528 27.5843 79.3561 27.6908 79.6341C27.7973 79.9121 27.9838 80.1523 28.2267 80.3244C28.4696 80.4966 28.758 80.5929 29.0555 80.6011C29.3529 80.6094 29.6461 80.5293 29.898 80.371C30.1498 80.2126 30.349 79.9831 30.4704 79.7114C30.5918 79.4398 30.63 79.1382 30.5801 78.8447C30.5302 78.5512 30.3944 78.2791 30.19 78.0626C29.9156 77.7727 29.5374 77.6036 29.1385 77.5924C28.7397 77.5812 28.3528 77.7289 28.0629 78.003L28.0601 78.0034Z"
                fill="#C6E7FA"
            />
            <path
                d="M20.319 70.1888C19.8471 70.635 19.5182 71.2114 19.3738 71.8449C19.2295 72.4784 19.2764 73.1406 19.5083 73.7477C19.7403 74.3549 20.1471 74.8797 20.6772 75.2558C21.2072 75.6318 21.8368 75.8423 22.4862 75.8606C23.1356 75.8788 23.7757 75.7039 24.3256 75.3582C24.8754 75.0124 25.3102 74.5112 25.5751 73.918C25.8399 73.3248 25.9229 72.6663 25.8134 72.0256C25.704 71.385 25.4071 70.7911 24.9603 70.3191C24.6639 70.0057 24.3088 69.7537 23.9151 69.5776C23.5214 69.4014 23.097 69.3045 22.666 69.2924C22.235 69.2803 21.8059 69.3532 21.4032 69.507C21.0005 69.6608 20.6321 69.8925 20.319 70.1888V70.1888Z"
                fill="#C6E7FA"
            />
            <defs>
                <clipPath id="clip0_1_359_cc">
                    <rect
                        width="55.9011"
                        height="60"
                        fill="white"
                        transform="translate(34 31)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
