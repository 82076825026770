import React, { ReactElement } from "react";

export default function EmployeeSeeksMedicalCareIcon(): ReactElement {
    return (
        <svg
            width="130"
            height="127"
            viewBox="0 0 130 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="61" cy="62" r="61" fill="#6A4A9E" />
            <g clipPath="url(#clip0_1_359_0)">
                <path
                    d="M43.6503 86.6853C43.6117 86.6855 43.5731 86.6833 43.5347 86.6785C43.4114 86.6621 43.2926 86.6205 43.1856 86.5561C43.0785 86.4918 42.9854 86.4061 42.9118 86.3042L35.0419 75.3311C34.9138 75.1535 34.852 74.9354 34.8673 74.7158C34.8826 74.4962 34.9742 74.2892 35.1257 74.1317C35.5325 73.714 45.1723 63.9497 54.3718 67.7307C58.0422 69.2397 62.2065 70.0504 67.4785 70.2826C71.8379 70.474 74.2491 71.7032 74.6477 73.9361C74.6753 74.1598 74.8067 75.6586 73.5425 77.0153C72.1243 78.538 69.59 79.1989 65.9941 78.9718C60.2893 78.7362 53.553 78.9641 52.6638 79.7374C52.4827 79.897 52.2483 79.9801 52.0088 79.9699C51.7692 79.9596 51.5426 79.8566 51.3753 79.6821C51.212 79.512 51.1213 79.2834 51.1229 79.0458C51.1244 78.8081 51.2182 78.5808 51.3837 78.4129C53.028 76.7644 62.2149 76.9575 66.0879 77.1166C69.8738 77.3564 71.5081 76.499 72.2155 75.7394C72.8979 75.007 72.8418 74.2176 72.8409 74.2083C72.5538 72.6091 69.3078 72.2212 67.399 72.1378C61.9872 71.9005 57.5013 71.0226 53.6852 69.4541C46.7731 66.6096 39.1016 72.9655 36.9893 74.8981L43.8889 84.5179C45.7308 83.5422 50.9216 81.6164 62.3505 82.4262C63.6499 82.5181 64.9225 82.5538 66.1289 82.5342H66.1448C66.3845 82.5344 66.6145 82.6303 66.7852 82.8012C66.9559 82.9721 67.0537 83.2043 67.0574 83.4478C67.0594 83.5698 67.0377 83.6909 66.9936 83.8043C66.9495 83.9178 66.8838 84.0212 66.8003 84.1089C66.7167 84.1965 66.6171 84.2665 66.5069 84.3149C66.3967 84.3634 66.2782 84.3893 66.1582 84.3912C64.9024 84.4107 63.5745 84.3741 62.2233 84.2789C48.4317 83.3058 44.2623 86.4386 44.2229 86.4701C44.0596 86.6069 43.8544 86.6815 43.6427 86.6811L43.6503 86.6853Z"
                    fill="white"
                />
                <path
                    d="M69.6628 84.1657C69.4206 84.1655 69.1884 84.0676 69.0173 83.8935C68.9323 83.8073 68.8649 83.705 68.8189 83.5923C68.773 83.4796 68.7493 83.3588 68.7494 83.2368C68.752 82.9913 68.848 82.7562 69.0173 82.581C69.1919 82.4137 69.4228 82.3206 69.6628 82.3206C69.9028 82.3206 70.1337 82.4137 70.3083 82.581C70.4778 82.7562 70.5741 82.9912 70.577 83.2368C70.5769 83.3588 70.5532 83.4797 70.507 83.5923C70.4609 83.705 70.3934 83.8074 70.3083 83.8935C70.2236 83.9797 70.123 84.0482 70.0122 84.0949C69.9015 84.1416 69.7827 84.1656 69.6628 84.1657V84.1657Z"
                    fill="white"
                />
                <path
                    d="M73.1733 83.555C72.9676 83.5547 72.768 83.4839 72.6068 83.3541C72.4456 83.2243 72.3322 83.043 72.285 82.8396C72.2304 82.6007 72.2712 82.3495 72.3985 82.141C72.5257 81.9325 72.729 81.7836 72.964 81.727C78.2669 80.4511 82.4598 77.7027 85.7861 73.3177C86.661 72.1634 87.1256 70.7972 87.0971 69.4711C87.0544 69.4711 85.3097 68.8221 77.7755 73.6281C77.5696 73.7575 77.322 73.7993 77.086 73.7445C76.85 73.6898 76.6448 73.5428 76.5146 73.3355C76.3871 73.1273 76.3459 72.8763 76.3998 72.6372C76.4538 72.3982 76.5986 72.1906 76.8026 72.0596C82.5795 68.3772 86.3512 66.9719 88.0097 67.8804C88.2866 68.0304 88.5181 68.2541 88.6793 68.5276C88.8405 68.8011 88.9254 69.114 88.9248 69.4328C88.9625 71.1817 88.363 72.9655 87.2345 74.4516C83.6453 79.1879 79.1134 82.1574 73.3884 83.5303C73.3193 83.5471 73.2485 83.5557 73.1775 83.5559L73.1733 83.555Z"
                    fill="white"
                />
                <path
                    d="M30.1576 79.1751L36.091 89.7467L42.3501 85.5199L35.5292 76.0082L30.1576 79.1751ZM35.782 91.9983C35.711 91.9984 35.6402 91.9902 35.5711 91.9736C35.4487 91.9446 35.3335 91.8902 35.2327 91.814C35.1318 91.7377 35.0475 91.6412 34.985 91.5304L28.1198 79.2941C28.0003 79.0813 27.9685 78.8291 28.0312 78.5925C28.0939 78.3559 28.2461 78.1541 28.4546 78.031L35.3199 73.982C35.5152 73.8667 35.7456 73.829 35.9667 73.8761C36.1878 73.9233 36.3838 74.0519 36.5171 74.2372L44.387 85.2103C44.4599 85.3115 44.5119 85.4267 44.5396 85.549C44.5674 85.6712 44.5705 85.7979 44.5486 85.9214C44.5263 86.0446 44.48 86.1619 44.4122 86.2665C44.3445 86.3712 44.2568 86.4609 44.1543 86.5304L36.2844 91.8443C36.1355 91.9449 35.9608 91.9988 35.782 91.9991V91.9983Z"
                    fill="white"
                />
                <path
                    d="M59.7015 62.723H64.0893V53.0105C64.0893 52.7643 64.1855 52.5282 64.3568 52.354C64.5281 52.1798 64.7604 52.0819 65.0028 52.0816H68.1365V46.5338C68.1347 45.6771 67.799 44.856 67.2027 44.2502C66.6064 43.6444 65.7983 43.3033 64.955 43.3015H58.8433C58.0001 43.3033 57.1919 43.6444 56.5956 44.2502C55.9994 44.856 55.6636 45.6771 55.6618 46.5338V52.0816H58.7956C59.0379 52.0819 59.2702 52.1798 59.4415 52.354C59.6128 52.5282 59.709 52.7643 59.709 53.0105V62.723H59.7015ZM65.0028 64.5799H58.788C58.5457 64.5799 58.3133 64.4822 58.1419 64.3082C57.9704 64.1341 57.874 63.8981 57.8738 63.6519V53.9394H54.7401C54.4977 53.9392 54.2654 53.8412 54.0941 53.6671C53.9229 53.4929 53.8267 53.2567 53.8267 53.0105V46.5338C53.828 45.1836 54.3565 43.889 55.2962 42.9342C56.236 41.9795 57.5101 41.4425 58.8391 41.4411H64.9508C66.2797 41.4427 67.5536 41.9798 68.4932 42.9345C69.4327 43.8893 69.9611 45.1837 69.9625 46.5338V53.0105C69.9625 53.2567 69.8663 53.4929 69.695 53.6671C69.5237 53.8412 69.2914 53.9392 69.0491 53.9394H65.9153V63.6519C65.9151 63.8981 65.8187 64.1341 65.6473 64.3082C65.4758 64.4822 65.2434 64.5799 65.0011 64.5799H65.0028Z"
                    fill="white"
                />
                <path
                    d="M61.895 33.8569C61.4287 33.8569 60.9815 34.0451 60.6518 34.3801C60.3221 34.7151 60.1368 35.1695 60.1368 35.6432C60.1368 36.117 60.3221 36.5714 60.6518 36.9064C60.9815 37.2414 61.4287 37.4296 61.895 37.4296C62.3613 37.4296 62.8085 37.2414 63.1382 36.9064C63.4679 36.5714 63.6531 36.117 63.6531 35.6432C63.6531 35.1695 63.4679 34.7151 63.1382 34.3801C62.8085 34.0451 62.3613 33.8569 61.895 33.8569V33.8569ZM61.895 39.2899C60.9439 39.2888 60.032 38.9044 59.3595 38.2211C58.6869 37.5378 58.3086 36.6113 58.3075 35.6449C58.3086 34.6786 58.6869 33.7521 59.3595 33.0688C60.032 32.3855 60.9439 32.0011 61.895 32C62.8462 32.0009 63.7583 32.3852 64.431 33.0685C65.1038 33.7519 65.4822 34.6784 65.4833 35.6449C65.4822 36.6115 65.1038 37.538 64.431 38.2214C63.7583 38.9047 62.8462 39.289 61.895 39.2899V39.2899Z"
                    fill="white"
                />
            </g>
            <path
                d="M104.091 73C104.606 73 105.1 73.2045 105.464 73.5685C105.829 73.9325 106.033 74.4262 106.033 74.941L106.033 77.3424L108.469 77.3423C108.984 77.3423 109.478 77.5468 109.843 77.9109C110.207 78.2749 110.411 78.7686 110.411 79.2834C110.411 79.7982 110.207 80.2919 109.843 80.6559C109.478 81.0199 108.984 81.2244 108.469 81.2244L106.033 81.2244L106.033 83.6277C106.033 84.1425 105.829 84.6362 105.464 85.0002C105.1 85.3642 104.606 85.5687 104.091 85.5687C103.576 85.5687 103.082 85.3642 102.717 85.0002C102.353 84.6362 102.149 84.1425 102.149 83.6277L102.149 81.227L99.7102 81.227C99.195 81.227 98.701 81.0225 98.3367 80.6585C97.9724 80.2945 97.7678 79.8008 97.7678 79.286C97.7678 78.7712 97.9724 78.2775 98.3367 77.9135C98.701 77.5494 99.195 77.3449 99.7102 77.3449L102.146 77.3449L102.146 74.9436C102.146 74.6883 102.196 74.4354 102.293 74.1994C102.391 73.9634 102.534 73.749 102.715 73.5685C102.896 73.3879 103.11 73.2448 103.346 73.1472C103.582 73.0497 103.835 72.9996 104.091 73V73Z"
                fill="#C6E7FA"
            />
            <path
                d="M92.2927 87.4466C92.2349 87.8856 92.3087 88.3318 92.5047 88.7289C92.7007 89.1259 93.0101 89.4561 93.3938 89.6774C93.7776 89.8988 94.2184 90.0015 94.6604 89.9725C95.1025 89.9436 95.5261 89.7843 95.8775 89.5147C96.2289 89.2452 96.4925 88.8775 96.6348 88.4583C96.7771 88.039 96.7917 87.587 96.6769 87.1594C96.5621 86.7318 96.323 86.3477 95.9898 86.0559C95.6566 85.764 95.2443 85.5774 94.805 85.5197C94.2161 85.4424 93.6206 85.6019 93.1495 85.9633C92.6784 86.3246 92.3702 86.8582 92.2927 87.4466V87.4466Z"
                fill="#C6E7FA"
            />
            <circle cx="69" cy="65" r="60.5" stroke="#C6E7FA" />
            <path
                d="M28.4698 41.1142C27.9268 41.4706 27.5017 41.9802 27.2483 42.5785C26.995 43.1768 26.9247 43.8369 27.0464 44.4754C27.1681 45.1138 27.4763 45.7019 27.932 46.1653C28.3878 46.6287 28.9706 46.9465 29.6067 47.0786C30.2428 47.2106 30.9037 47.151 31.5057 46.9072C32.1077 46.6634 32.6239 46.2464 32.9888 45.709C33.3538 45.1716 33.5512 44.5378 33.556 43.8879C33.5608 43.2381 33.3729 42.6012 33.016 42.058C32.7793 41.6974 32.4739 41.387 32.1173 41.1444C31.7607 40.9018 31.3599 40.7318 30.9378 40.6442C30.5156 40.5566 30.0804 40.553 29.6569 40.6336C29.2335 40.7143 28.8301 40.8776 28.4698 41.1142V41.1142Z"
                fill="#C6E7FA"
            />
            <path
                d="M35.5676 35.4487C35.3191 35.6123 35.1248 35.846 35.0091 36.1202C34.8934 36.3944 34.8615 36.6968 34.9175 36.9892C34.9736 37.2816 35.115 37.5508 35.3238 37.7629C35.5327 37.975 35.7997 38.1205 36.0911 38.1809C36.3824 38.2414 36.6851 38.214 36.9609 38.1024C37.2366 37.9907 37.4731 37.7998 37.6403 37.5537C37.8076 37.3076 37.8981 37.0174 37.9006 36.7197C37.903 36.4221 37.8172 36.1303 37.654 35.8813C37.4348 35.5477 37.0922 35.3147 36.7015 35.2336C36.3108 35.1525 35.904 35.2299 35.5705 35.4488L35.5676 35.4487Z"
                fill="#C6E7FA"
            />
            <defs>
                <clipPath id="clip0_1_359_0">
                    <rect
                        width="60.9231"
                        height="60"
                        fill="white"
                        transform="translate(28 32)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
